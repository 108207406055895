import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import {IoCall, IoMail} from 'react-icons/io5';

import {$profile} from '../models/auth/auth';
import {showToastFx} from '../models/components/components';
import {createTicketFx} from '../models/contact/contact';

import css from './ContactUs.module.scss';
import FormControlError from '../components/FormControlError';
import Head from '../components/Head';
import Loader from '../components/Loader';

const maxMessageCharacters = 200;

export default function ContactUs() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const profile = useStore($profile);
  const isSending = useStore(createTicketFx.pending);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setmessage] = useState('');

  const pageRes = useSWR(`/contact-page`);
  const page = pageRes.data?.items[0].data;

  const remainingCharacters = maxMessageCharacters - message.length;

  const onSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (message) {
      const res = await createTicketFx(message);
      if (res === true) {
        setmessage('');
        setIsSubmitted(false);
        showToastFx({text: t('contact-form-success-message'), status: 'success'});
      }
    }
  };

  if (!page && pageRes.isValidating)
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );

  return (
    <div className={`pad ${css.ContactUs}`}>
      <Head title={t('contact-us')} description={page?.metaDescription ? page.metaDescription[language] : ''} />

      <div className="page-header">
        <h1 className="fs --42 --black page-title">{t('contact-us').toUpperCase()}</h1>
        <h2 className="fs --25 --medium page-subtitle">{t('we-love-hearing-from-you')!}</h2>
      </div>

      {profile && (
        <>
          <div className={css.contactMember}>
            <svg viewBox="0 0 24 24">
              <use xlinkHref="#svg-avatar" />
            </svg>
            <div>
              <p className={css.username}>
                {profile?.firstname} {profile?.lastname}
              </p>
              <p>{t('write-the-reason-you-are-contacting-us')}</p>
            </div>
          </div>

          <form className={css.contactForm} onSubmit={onSubmit}>
            <div className="form-control">
              <textarea
                value={message}
                placeholder={t('type-here')}
                onChange={(e) => {
                  const {value} = e.currentTarget;
                  if (maxMessageCharacters - value.length >= 0) setmessage(value);
                }}
              />
              <p className={css.remainingCharacters}>
                {remainingCharacters} {t('characters-remaining')}
              </p>
              {isSubmitted && !message && <FormControlError />}
            </div>

            <div className={css.action}>
              <button type="submit" className="button" disabled={isSending}>
                {t('submit-your-message').toUpperCase()}
              </button>
            </div>
          </form>
        </>
      )}

      <div className={css.contactInfo}>
        <h3 className={css.title}>{t('contact-us-via')}</h3>

        <div className={css.item}>
          <IoCall size={20} />
          <span>{t('phone')}</span>
          <a href={`tel:${page?.phone.iv}`}>{page?.phone.iv}</a>
        </div>

        <div className={css.item}>
          <IoMail size={20} />
          <span>Email</span>
          <a href={`mailto:${page?.email.iv}`}>{page?.email.iv}</a>
        </div>
      </div>
    </div>
  );
}
