import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {useStore} from 'effector-react';
import settings from '../../config/settings';

import {$profile, $registrationFlow, updateRegistrationFlow, OTPRequestFx} from '../../models/auth/auth';

import css from './Auth.module.scss';
import Head from '../../components/Head';
import FormControlError from '../../components/FormControlError';

export default function LoginRequest() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const navigate = useNavigate();
  const profile = useStore($profile);
  const localePrefix = language === settings.defaultLocale ? '' : `/${language}`;

  useEffect(() => {
    if (profile) navigate(`${localePrefix}/`);
  }, [profile, navigate, localePrefix]);

  return (
    <div className={css.LoginRequest}>
      <Head title={t('login')} description={``} />

      <div className="container pad">
        <div className="page-header">
          <h1 className="fs --42 --black page-title">{t('login').toUpperCase()}</h1>
          <div className="fs --25 --medium page-intro">
            {t('enter-your-mobile-number-to-login-or-register-as-a-happy-member')}
          </div>
        </div>

        <LoginRequestForm />
      </div>
    </div>
  );
}

function LoginRequestForm() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const navigate = useNavigate();
  const {state} = useLocation();
  const localePrefix = language === settings.defaultLocale ? '' : `/${language}`;

  const {mobile} = useStore($registrationFlow);
  const isLoading = useStore(OTPRequestFx.pending);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const isValidMobile = (mobile: string) => {
    const rx = new RegExp('6[0-9]{8}$');
    return rx.test(mobile);
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (isValidMobile(mobile)) {
      const res = await OTPRequestFx({mobile});
      if (res) navigate(`${localePrefix}/login/verify`, {state});
    }
  };

  return (
    <form className={css.LoginRequestForm} onSubmit={onSubmit}>
      <fieldset>
        <div className="form-control">
          <input
            type="tel"
            name="mobile"
            placeholder="6XXXXXXXX"
            autoFocus
            value={mobile}
            onChange={(e) => {
              const {value} = e.currentTarget;
              updateRegistrationFlow({mobile: value});
            }}
          ></input>
          {isSubmitted && !isValidMobile(mobile) && <FormControlError />}
        </div>
      </fieldset>

      <button className="button" disabled={isLoading}>
        {t('request-otp').toUpperCase()}
      </button>
    </form>
  );
}
