import React from 'react';
import {useTranslation} from 'react-i18next';
import useSWR from 'swr';

import Head from '../components/Head';
import Loader from '../components/Loader';

type Props = {
  slug: string;
};

export default function TheProgram({slug}: Props) {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const pageRes = useSWR(`/${slug}-page`);
  const page = pageRes.data?.items[0].data;

  return (
    <div className="container pad">
      <Head title={t(slug)} description={page?.metaDescription ? page.metaDescription[language] : ''} />

      <div className="top-section-with-bg">
        <div className="container --s pad" style={{minHeight: '30vh'}}>
          <div className="page-header">
            <h1 className="fs --42 --black page-title">{t(slug)}</h1>
          </div>

          {!page && pageRes.isValidating && <Loader />}

          <div className={`textblock`} dangerouslySetInnerHTML={{__html: page?.description[language]}} />
        </div>
      </div>
    </div>
  );
}
