import React, {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {useStore} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {SWRConfig} from 'swr';

import {$isNavOpen, $fetchClient, $clientsInitialized, initAxios} from './models/app';
import {showToastFx} from './models/components/components';

import CacheBuster from './components/CacheBuster';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollManager from './components/ScrollManager';
import Toast from './components/Toast';
import Loader from './components/Loader';

import {ReactComponent as UISvg} from './config/ui.svg';
import Popup from './components/Popup';

const _W: any = window;

function App() {
  const {pathname} = useLocation();
  const isNavOpen = useStore($isNavOpen);
  const fetcher = useStore($fetchClient);
  const clientsInitialized = useStore($clientsInitialized);
  const {i18n, t} = useTranslation();
  const isHomePage = pathname === '/' || pathname === `/${i18n.language}` || pathname === `/${i18n.language}/`;

  useEffect(() => {
    window.addEventListener('focus', () => {
      if (!window.navigator.onLine) {
        showToastFx({text: t('no-internet-connection'), status: 'warning', critical: true});
      }
    });
    window.addEventListener('online', () => {
      showToastFx({text: t('back-online'), status: 'success'});
    });
    window.addEventListener('offline', () => {
      showToastFx({text: t('no-internet-connection'), status: 'warning', critical: true});
    });
  }, [t]);

  useEffect(() => {
    initAxios();
  }, [i18n.language]);

  useEffect(() => {
    function injectTawkScript() {
      /* eslint-disable */
      const Tawk_API = _W.Tawk_API || {};
      const Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/5d6626e8eb1a6b0be609b92c/default';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode?.insertBefore(s1, s0);
      })();
    }

    if (process.env.NODE_ENV === 'production') injectTawkScript();
  }, []);

  return (
    <CacheBuster pathname={pathname}>
      <SWRConfig value={{fetcher}}>
        <div id="app" data-nav-open={isNavOpen}>
          <UISvg />
          <ScrollManager />
          <Popup />
          <Toast />
          {!clientsInitialized && (
            <div className="loader-container">
              <Loader />
            </div>
          )}
          {clientsInitialized && (
            <>
              <Header />

              <main id="main">
                {!isHomePage && (
                  <div id="main-top-bg">
                    <svg viewBox="0 0 1920 323">
                      <use xlinkHref="#svg-header-bg" />
                    </svg>
                  </div>
                )}

                <Outlet />
              </main>

              <Footer />
            </>
          )}
        </div>
      </SWRConfig>
    </CacheBuster>
  );
}

export default App;
