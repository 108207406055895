import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import useSWR from 'swr';
import axios from 'axios';
import settings from '../config/settings';
import {validateEmail} from '../lib/utils';

import {showToastFx} from '../models/components/components';

import FormControlError from '../components/FormControlError';
import Head from '../components/Head';

export default function BecomeAPartner() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const [isSubmitting, setisSubmitting] = useState(false);

  const partnersComp = useSWR(`/become-a-partner-comp`).data?.items[0].data;

  const {register, handleSubmit, reset, formState} = useForm<any>();

  const onSubmit = async (values: any) => {
    setisSubmitting(true);
    let payload: any = {};
    for (const key in values) {
      payload[key] = {iv: values[key]};
    }

    const res = await axios.post(`/become-a-partner-form`, payload, {
      baseURL: `${settings.domain}${settings.contentApi}`,
    });

    setisSubmitting(false);

    if (res.data) {
      reset();
      showToastFx({text: t('thank-you-for-contacting-us'), status: 'success'});
    }
  };

  return (
    <div className="BecomeAPartner">
      <Head title={partnersComp?.title[language]} description={partnersComp?.teaser[language]} />

      <div className="container pad">
        <div className="page-header">
          <h1 className="fs --42 --black page-title">{partnersComp?.title[language].toUpperCase()}</h1>
          <h2 className="fs --25 --medium page-subtitle">{partnersComp?.teaser[language]}</h2>
        </div>

        <form className="BecomeAPartner-form">
          <fieldset>
            <div className="form-control">
              <input {...register('firstname', {required: true})} type="text" placeholder={t('first-name')} />
              {formState.isSubmitted && formState.errors.firstname && <FormControlError />}
            </div>

            <div className="form-control">
              <input {...register('lastname', {required: true})} type="text" placeholder={t('last-name')} />
              {formState.isSubmitted && formState.errors.lastname && <FormControlError />}
            </div>

            <div className="form-control">
              <input {...register('nuis', {required: true})} type="text" placeholder="NUIS" />
              {formState.isSubmitted && formState.errors.nuis && <FormControlError />}
            </div>

            <div className="form-control">
              <input
                {...register('businessCategory', {required: true})}
                type="text"
                placeholder={t('business-category')}
              />
              {formState.isSubmitted && formState.errors.businessCategory && <FormControlError />}
            </div>

            <div className="form-control">
              <input {...register('city', {required: true})} type="text" placeholder={t('city')} />
              {formState.isSubmitted && formState.errors.city && <FormControlError />}
            </div>

            <div className="form-control">
              <input
                {...register('email', {
                  required: true,
                  validate: {email: (value) => (value ? validateEmail(value) : true)},
                })}
                type="email"
                placeholder="Email"
              />
              {formState.isSubmitted && formState.errors.email && <FormControlError />}
            </div>

            <div className="form-control">
              <input {...register('phone', {required: true})} type="tel" placeholder={t('phone')} />
              {formState.isSubmitted && formState.errors.phone && <FormControlError />}
            </div>
          </fieldset>

          <div className="BecomeAPartner-action">
            <button type="button" className="button" disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
              {t('send-request').toUpperCase()}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
