import React from 'react';
import packageJson from '../../package.json';

const isProduction = process.env.NODE_ENV === 'production';

const storedVersion = localStorage.getItem('app_version');
if (!storedVersion) localStorage.setItem('app_version', packageJson.version);
let currentVersion: string;

const _W: any = window;
_W.appVersion = storedVersion || packageJson.version;

interface Props {
  pathname: string;
  children: JSX.Element;
}

export default class CacheBuster extends React.Component<Props> {
  componentDidMount() {
    if (isProduction) {
      this.fetchMeta();
      window.addEventListener('focus', () => this.fetchMeta());
    }
  }

  async fetchMeta() {
    const storedVersion = localStorage.getItem('app_version');
    const res = await fetch(`/meta.json?v=${+new Date()}`);
    const json = await res.json();

    currentVersion = json.version;

    if (currentVersion !== storedVersion) {
      this.refreshCacheAndReload();
    }
  }

  refreshCacheAndReload() {
    localStorage.setItem('app_version', currentVersion);

    if (caches) {
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
    }

    window.location.reload();
  }

  render() {
    return this.props.children;
  }
}
