import React, {useState} from 'react';
import settings from '../config/settings';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export default function Image(props: Props) {
  const [isReady, setisReady] = useState(false);
  const src = `${settings.domain}${settings.assetsApi}/${props.src}`;
  const srcSet = props.srcSet
    ? `${src}?width=600 600w,
     ${src}?width=900 900w,
     ${src}?width=1280 1280w,
     ${src}?width=1600 1600w,
     ${src}?width=1920 1920w
    `
    : '';

  const onLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setisReady(true);
    if (props.onLoad) props.onLoad(e);
  };

  return (
    <div className="Image">
      <img
        {...props}
        alt={props.alt}
        src={src}
        srcSet={srcSet}
        onLoad={onLoad}
        onError={() => setisReady(true)}
        style={{opacity: isReady ? 1 : 0, transition: '.2s ease-out opacity'}}
      />

      {!isReady && <div className="Skeleton" />}
    </div>
  );
}
