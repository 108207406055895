import React from 'react';
import {Link, LinkProps} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import settings from '../config/settings';

export default function LinkComp(props: LinkProps) {
  const {pathname} = useLocation();
  const {i18n} = useTranslation();

  const locale = i18n.language;

  const localePrefix = locale === settings.defaultLocale ? '' : `/${locale}`;
  const currPath = locale === settings.defaultLocale ? pathname : pathname.slice(3);

  return <Link {...props} to={`${localePrefix}${props.to}`} data-active={props.to === currPath} />;
}
