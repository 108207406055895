import {createEffect, attach} from 'effector';
import axios from 'axios';

import {ApiResponse} from '../app_types';
import {$token} from '../auth/auth';
import {showToastFx} from '../components/components';

const createTicket = createEffect(async ({body, token}: {body: string; token: string}) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/ticket', {body, token, fromWeb: true});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  if (data.value) showToastFx({text: data.message, status: 'success'});
  return true;
});

export const createTicketFx = attach({
  effect: createTicket,
  source: $token,
  mapParams: (body: string, $token: string) => ({body, token: $token}),
});
