import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {useStore} from 'effector-react';
import settings from '../../config/settings';

import {
  $registrationFlow,
  $OTP,
  $profile,
  updateRegistrationFlow,
  resetRegistrationFlow,
  setToken,
  OTPValidateFx,
  loginFx,
} from '../../models/auth/auth';

import css from './Auth.module.scss';
import Head from '../../components/Head';
import Loader from '../../components/Loader';

const pinRequestTimeoutSeconds = 90;
let pinRaf: any;

export default function LoginVerify() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {mobile} = useStore($registrationFlow);
  const storedOTP = useStore($OTP);
  const profile = useStore($profile);
  const isValidatingOTP = useStore(OTPValidateFx.pending);
  const isLoggingIn = useStore(loginFx.pending);
  const localePrefix = language === settings.defaultLocale ? '' : `/${language}`;

  const initialOTP = {1: '', 2: '', 3: '', 4: '', 5: '', 6: ''};

  const [secondsCountdown, setSecondsCountdown] = useState(pinRequestTimeoutSeconds);
  const [OTP, setOTP] = useState(initialOTP);

  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const inputRef6 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => cancelAnimationFrame(pinRaf);
  }, []);

  useEffect(() => {
    if (profile) {
      navigate(`${localePrefix}/`);
    } else {
      if (!mobile) navigate(`${localePrefix}/login`);
    }
  }, [profile, mobile, navigate, localePrefix]);

  useEffect(() => {
    const getSecondsElapsed = () => Number(((+new Date() - storedOTP.timestamp) / 1000).toFixed(0));

    function startTimer() {
      const countdown = pinRequestTimeoutSeconds - getSecondsElapsed();
      setSecondsCountdown(countdown);
      pinRaf = requestAnimationFrame(startTimer);
      if (countdown <= 0) {
        cancelAnimationFrame(pinRaf);
        resetRegistrationFlow();
        navigate(`${localePrefix}/login`);
        return;
      }
    }

    cancelAnimationFrame(pinRaf);
    startTimer();
  }, [storedOTP, navigate, localePrefix]);

  useEffect(() => {
    async function validateMobile() {
      const otpString = Object.values(OTP).join('');
      const res = await OTPValidateFx({mobile, pin: otpString});

      if (res === false) return navigate(`${localePrefix}/register`);

      if (res?.value) {
        if (res.value.active) {
          loginFx();
          return navigate(state?.from ? `${localePrefix}${state.from}` : `${localePrefix}/`);
        }

        setToken(res.token);
        updateRegistrationFlow(res.value);
        navigate(`${localePrefix}/register`);
      }

      return null;
    }

    if (OTP[1] && OTP[2] && OTP[3] && OTP[4] && OTP[5] && OTP[6]) {
      validateMobile();
    }
  }, [OTP, mobile, navigate, localePrefix, state]);

  const onChange = (id: number, value: string) => {
    const isNumericRx = new RegExp('\\d');
    const isNumeric = isNumericRx.test(value);
    if (!isNumeric) return;

    switch (id) {
      case 1:
        setOTP({...OTP, 1: value});
        if (value) inputRef2.current?.focus();
        break;
      case 2:
        setOTP({...OTP, 2: value});
        if (value) inputRef3.current?.focus();
        break;
      case 3:
        setOTP({...OTP, 3: value});
        if (value) inputRef4.current?.focus();
        break;
      case 4:
        setOTP({...OTP, 4: value});
        if (value) inputRef5.current?.focus();
        break;
      case 5:
        setOTP({...OTP, 5: value});
        if (value) inputRef6.current?.focus();
        break;
      case 6:
        setOTP({...OTP, 6: value});
        break;
    }
  };

  const onKeyUp = (id: number, key: string) => {
    if (key === 'Backspace') {
      switch (id) {
        case 1:
          setOTP({...OTP, 1: ''});
          break;
        case 2:
          inputRef1.current?.focus();
          setOTP({...OTP, 2: ''});
          break;
        case 3:
          inputRef2.current?.focus();
          setOTP({...OTP, 3: ''});
          break;
        case 4:
          inputRef3.current?.focus();
          setOTP({...OTP, 4: ''});
          break;
        case 5:
          inputRef4.current?.focus();
          setOTP({...OTP, 5: ''});
          break;
        case 6:
          inputRef5.current?.focus();
          setOTP({...OTP, 6: ''});
          break;
      }
    }
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
  };

  return (
    <div className={css.LoginVerify}>
      <Head title={t('verify-code')} description={``} />

      <div className="container pad">
        <div className="page-header">
          <h1 className="fs --42 --black page-title">{t('verify-code').toUpperCase()}</h1>
          <h2 className="fs --25 --medium page-subtitle">{t('please-enter-otp-verification')}</h2>

          <div className="fs --25 --medium page-intro">
            {mobile && secondsCountdown > 0 && (
              <span className="fs --18 --medium">
                {t('code-sent-to')} {mobile} <br />
                {t('expires-in')} {secondsCountdown}''
              </span>
            )}
          </div>
        </div>

        <form className={css.LoginVerifyForm} onSubmit={onSubmit}>
          <fieldset className={css.inputFields}>
            <input
              ref={inputRef1}
              type="tel"
              maxLength={1}
              value={OTP[1]}
              onChange={(e) => onChange(1, e.target.value)}
              onKeyUp={({nativeEvent}) => onKeyUp(1, nativeEvent.key)}
              autoFocus
            />
            <input
              ref={inputRef2}
              type="tel"
              maxLength={1}
              value={OTP[2]}
              onChange={(e) => onChange(2, e.target.value)}
              onKeyUp={({nativeEvent}) => onKeyUp(2, nativeEvent.key)}
            />
            <input
              ref={inputRef3}
              type="tel"
              maxLength={1}
              value={OTP[3]}
              onChange={(e) => onChange(3, e.target.value)}
              onKeyUp={({nativeEvent}) => onKeyUp(3, nativeEvent.key)}
            />
            <input
              ref={inputRef4}
              type="tel"
              maxLength={1}
              value={OTP[4]}
              onChange={(e) => onChange(4, e.target.value)}
              onKeyUp={({nativeEvent}) => onKeyUp(4, nativeEvent.key)}
            />
            <input
              ref={inputRef5}
              type="tel"
              maxLength={1}
              value={OTP[5]}
              onChange={(e) => onChange(5, e.target.value)}
              onKeyUp={({nativeEvent}) => onKeyUp(5, nativeEvent.key)}
            />
            <input
              ref={inputRef6}
              type="tel"
              maxLength={1}
              value={OTP[6]}
              onChange={(e) => onChange(6, e.target.value)}
              onKeyUp={({nativeEvent}) => onKeyUp(6, nativeEvent.key)}
            />
          </fieldset>

          <div style={{height: '4rem'}}>{(isValidatingOTP || isLoggingIn) && <Loader />}</div>
        </form>
      </div>
    </div>
  );
}
