import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import {IoArrowBack} from 'react-icons/io5';

import {$profile} from '../../models/auth/auth';
import {activateBooklet5Fx, activateBooklet10Fx} from '../../models/booklets/booklets';
import {showToastFx} from '../../models/components/components';

import css from './Account.module.scss';
import Image from '../../components/Image';
import Link from '../../components/Link';
import Loader from '../../components/Loader';

export default function Booklets() {
  const itemsRes = useSWR(`/booklets`);
  const items = itemsRes.data?.items;

  return (
    <div className={css.Booklets}>
      {!items && itemsRes.isValidating && <Loader />}

      <div>
        {items?.map((item: any) => (
          <BookletItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
}

function BookletItem(item: any) {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  return (
    <Link className={css.BookletItem} to={`/account/booklets/${item.id}`}>
      <h3 className={css.title}>
        <span className="fs --42 --bold">{item.data.title[language]}</span>
      </h3>

      <div className={css.points}>
        <span className="fs --20 --bold">
          {item.data.points.iv} {t('points').toLowerCase()}
        </span>
      </div>

      <div style={{display: 'flex'}}>
        <div className={css.image}>
          <Image alt="happy graphic" src={item.data.teaserImage.iv} />
        </div>
        <p className={css.teaser}>
          <span className="fs --25">{item.data.teaser[language]}</span>
        </p>
      </div>

      <div className={css.logos}>
        <Image alt="partners logos" src={item.data.logosImage.iv} />
      </div>
    </Link>
  );
}

export function BookletDetail() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const navigate = useNavigate();
  const {slug} = useParams();
  const profile = useStore($profile);
  const isLoading5 = useStore(activateBooklet5Fx.pending);
  const isLoading10 = useStore(activateBooklet10Fx.pending);

  const pageRes = useSWR(`/booklets?$filter=id eq '${slug}'`);
  const page = pageRes.data?.items[0].data;

  const onActivateBooklet = async () => {
    let res;
    if (page?.points.iv === 5000) res = await activateBooklet5Fx();
    if (page?.points.iv === 10000) res = await activateBooklet10Fx();
    if (res) showToastFx({text: `${t('congratulations')}! ${t('you-ve-just-bought-a-booklet')}!`, status: 'success'});
  };

  if (!page && pageRes.isValidating)
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );

  return (
    <div className={css.BookletDetail}>
      {page && (
        <div className="container --s pad">
          <button aria-label="Back" className="back-button" onClick={() => navigate(-1)}>
            <IoArrowBack size={24} />
          </button>

          <h1 className={css.title}>
            <span className="fs --42 --bold">{page.title[language]}</span>
          </h1>

          <div className={css.image}>
            <Image alt={page.title[language]} src={`${page.mainImage.iv}?width=900`} />
          </div>

          <div className={`pad ${css.boxContainer}`}>
            <div
              className={`fs --22 textblock ${css.description}`}
              dangerouslySetInnerHTML={{__html: page.description[language]}}
            />

            <div className={css.action}>
              <p className={css.actionText}>
                {(profile?.poinBalance || 0) >= page.points.iv
                  ? `${t('yours-with-only')} ${page.points.iv} ${t('points').toLowerCase()}`
                  : `${t('keep-collecting-points-to-get-this-booklet')}!`}
              </p>
              <button
                className="button"
                disabled={!!(isLoading5 || isLoading10 || (profile?.poinBalance || 0) < page.points.iv)}
                onClick={onActivateBooklet}
              >
                {t('buy-the-booklet').toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
