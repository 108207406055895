import {createEvent, createEffect, createStore} from 'effector';

import {PopupType, ToastType} from './components_types';

/**
 * Toast
 */
const toastDisplaySeconds = 6;
let toastTimer: any;

export const showToastFx = createEffect((params: ToastType) => {
  return new Promise((resolve) => {
    clearTimeout(toastTimer);
    if (!params.critical) {
      toastTimer = setTimeout(resolve, toastDisplaySeconds * 1000);
    }
  });
});

export const hideToast = createEvent();

export const $toast = createStore<ToastType | null>(null)
  .on(showToastFx, (_, params) => params)
  .on(showToastFx.done, () => null)
  .on(hideToast, () => null);

/**
 * Popup
 */
export const setPopup = createEvent<PopupType>();
export const clearPopup = createEvent();

export const $popup = createStore<PopupType | null>(null)
  .on(setPopup, (_, params) => params)
  .on(clearPopup, () => null);
