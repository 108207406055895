import React, {useState, useEffect} from 'react';
import {useStore} from 'effector-react';
import {IoCloseOutline, IoCheckmarkCircleOutline, IoWarningOutline, IoAlertCircleOutline} from 'react-icons/io5';

import {$toast, hideToast} from '../models/components/components';
import {ToastType} from '../models/components/components_types';

let exitTimeout: any;

export default function Toast() {
  const toast = useStore($toast);
  const [currToast, setcurrToast] = useState<ToastType | null>(null);

  useEffect(() => {
    if (toast) {
      clearTimeout(exitTimeout);
      setcurrToast(toast);
    } else {
      exitTimeout = setTimeout(() => setcurrToast(null), 1000);
    }
  }, [toast]);

  return (
    <div className="Toast" data-visible={!!toast}>
      <div className="Toast-inner">
        <div className="Toast-status">
          {currToast?.status === 'success' && <IoCheckmarkCircleOutline size={26} />}
          {currToast?.status === 'warning' && <IoWarningOutline size={26} />}
          {currToast?.status === 'error' && <IoAlertCircleOutline size={26} />}
        </div>
        <button className="Toast-close" onClick={() => hideToast()}>
          <IoCloseOutline size={16} />
        </button>
        <p className="fs --medium Toast-text">{currToast?.text}</p>
      </div>
    </div>
  );
}
