import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import {
  IoCall,
  IoMail,
  IoGlobe,
  IoMap,
  IoArrowBack,
  IoLogoInstagram,
  IoLogoFacebook,
  IoLogoTwitter,
} from 'react-icons/io5';
import {gqlGetStores, gqlGetOffers} from '../lib/graphql_queries';

import {$gqlClient} from '../models/app';

import css from './Partners.module.scss';
import Head from '../components/Head';
import Image from '../components/Image';
import Link from '../components/Link';
import Loader from '../components/Loader';
import {OfferItem} from './Offers';

export default function Partners() {
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const gqlClient = useStore($gqlClient);

  const pageRes = useSWR(`/partners-page`);
  const page = pageRes.data?.items[0].data;

  const merchantsRes = useSWR(`/merchants?$orderby=data/title/iv asc`);
  const merchants = merchantsRes.data?.items;

  const offersRes = useSWR(gqlGetOffers(language), gqlClient);
  const offers = offersRes.data?.queryOffersContents;

  const isLoading = (!page || !merchants) && (pageRes.isValidating || merchantsRes.isValidating);

  const strategic = merchants?.filter((item: any) => item.data.partnerType?.iv[0] === 'strategic');
  const affiliate = merchants?.filter((item: any) => item.data.partnerType?.iv[0] === 'affiliate');

  return (
    <div className={css.Partners}>
      <Head title={t('happy-partners')} description={page?.metaDescription ? page.metaDescription[language] : ''} />

      {isLoading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}

      {!isLoading && page && offers && (
        <div className="container --l pad">
          <div className="page-header">
            <h1 className="fs --42 --black page-title">{t('happy-partners').toUpperCase()}</h1>
          </div>

          {merchants && (
            <section>
              <div className={css.sectionHeader}>
                <h2 className={`fs --34 --bold ${css.title}`}>{t('strategic-partners')}</h2>
                {page?.strategicPartnersTeaser && (
                  <p className={`fs --22 ${css.teaser}`}>{page.strategicPartnersTeaser[language]}</p>
                )}
              </div>

              <div className={css.grid}>
                {strategic?.map((item: any) => (
                  <PartnerItem key={item.id} item={item} lazy={false} />
                ))}
              </div>
            </section>
          )}

          {merchants && (
            <section>
              <div className={css.sectionHeader}>
                <h2 className={`fs --34 --bold ${css.title}`}>{t('affiliate-partners')}</h2>
                {page?.affiliatePartnersTeaser && (
                  <p className={`fs --22 ${css.teaser}`}>{page.affiliatePartnersTeaser[language]}</p>
                )}
              </div>

              <div className={css.grid}>
                {affiliate?.map((item: any) => (
                  <PartnerItem key={item.id} item={item} />
                ))}
              </div>
            </section>
          )}
        </div>
      )}

      {!isLoading && page && offers && (
        <div className={css.PartnersOffersComp}>
          <div className="container --m pad">
            <div className={css.PartnersOffersComp_inner}>
              <div className={css.compHeader}>
                <h2 className={`fs --48 --black ${css.title}`}>{page.ongoingOffersTitle[language]}</h2>
                {page.ongoingOffersTeaser && (
                  <p className={`fs --22 ${css.teaser}`}>{page.ongoingOffersTeaser[language]}</p>
                )}
              </div>

              <div className={css.compItems}>
                {offers.slice(0, 3).map((item: any) => (
                  <OfferItem key={item.id} {...item} />
                ))}
              </div>

              <Link className="button" to="/offers">
                {t('offers-page').toUpperCase()}
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

type PartnerItemProps = {
  item: any;
  variant?: 'small';
  lazy?: boolean;
};

export function PartnerItem({item, variant, lazy = true}: PartnerItemProps) {
  return (
    <Link className={css.PartnerItem} to={`/our-partners/${item.data.slug.iv}`} data-variant={variant}>
      <div className={css.image}>
        <Image alt={item.data.title.iv} src={`${item.data.image.iv}?width=600`} loading={lazy ? 'lazy' : 'eager'} />
      </div>
      <div className={css.logo}>
        <Image
          alt={`${item.data.title.iv} logo`}
          src={`${item.data.logo.iv}?width=200`}
          loading={lazy ? 'lazy' : 'eager'}
        />
      </div>
      {variant === 'small' ? (
        <div className={`fs --19 --bold ${css.title}`}>{item.data.title.iv}</div>
      ) : (
        <div className={`fs --32 --bold ${css.title}`}>{item.data.title.iv}</div>
      )}
    </Link>
  );
}

export function Partner() {
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const navigate = useNavigate();
  const {slug} = useParams();

  const pageRes = useSWR(`/merchants?$filter=data/slug/iv eq '${slug}'`);
  const pageItem = pageRes.data?.items[0];
  const page = pageItem ? pageItem.data : undefined;

  const stores = useSWR(pageItem ? `/stores?$filter=data/merchant/iv eq '${pageItem.id}'` : null)?.data?.items;

  return (
    <article className={css.Partner}>
      <Head title={page?.title.iv} description={``} />

      {!page && pageRes.isValidating && (
        <div className="loader-container">
          <Loader />
        </div>
      )}

      {page && (
        <div className="container pad">
          <section className={`top-section-with-bg ${css.topSection}`}>
            <div className="container --s pad">
              <button aria-label="Back" className="back-button" onClick={() => navigate(-1)}>
                <IoArrowBack size={24} />
              </button>

              <div className="page-header">
                <h1 className="fs --42 --black page-title">{page.title.iv.toUpperCase()}</h1>
              </div>

              <div className={css.image}>
                <Image alt={page.title.iv} src={`${page.image.iv}?width=900`} />
              </div>

              <div className={css.logo}>
                <Image alt={`${page.title.iv} logo`} src={`${page.logo.iv}?width=200`} />
              </div>

              <div className={css.contactButtons}>
                <a href={`mailto:${page.email.iv}`}>
                  <div className={css.icon}>
                    <IoMail size={20} />
                  </div>
                  <div className={css.text}>Email</div>
                </a>

                {page.website?.iv && (
                  <a href={page.website.iv} target="_blank" rel="noreferrer">
                    <div className={css.icon}>
                      <IoGlobe size={20} />
                    </div>
                    <div className={css.text}>Web</div>
                  </a>
                )}

                {page.instagram?.iv && (
                  <a href={page.instagram.iv} target="_blank" rel="noreferrer">
                    <div className={css.icon}>
                      <IoLogoInstagram size={20} />
                    </div>
                    <div className={css.text}>Instagram</div>
                  </a>
                )}

                {page.facebook?.iv && (
                  <a href={page.facebook.iv} target="_blank" rel="noreferrer">
                    <div className={css.icon}>
                      <IoLogoFacebook size={20} />
                    </div>
                    <div className={css.text}>Facebook</div>
                  </a>
                )}

                {page.twitter?.iv && (
                  <a href={page.twitter.iv} target="_blank" rel="noreferrer">
                    <div className={css.icon}>
                      <IoLogoTwitter size={20} />
                    </div>
                    <div className={css.text}>Twitter</div>
                  </a>
                )}
              </div>

              <div
                className={`fs --22 ${css.description}`}
                dangerouslySetInnerHTML={{__html: page.description[language]}}
              ></div>
            </div>
          </section>

          <section className={css.storesSection}>
            <div className="container --s pad">
              <h2 className={`fs --28 --black ${css.sectionTitle}`}>{t('store-locations').toUpperCase()}</h2>

              {stores?.map((item: any) => (
                <StoreItem key={item.id} {...item} />
              ))}
            </div>
          </section>
        </div>
      )}
    </article>
  );
}

function StoreItem(item: any) {
  const {
    i18n: {language},
  } = useTranslation();

  return (
    <Link className={css.StoreItem} to={`${item.id}`}>
      <h3 className={css.title}>
        <span className="fs --21 --bold">{item.data.title[language]}</span>
      </h3>
      <p className={css.address}>
        <span className="fs --18">{item.data.address[language]}</span>
      </p>
    </Link>
  );
}

export function Store() {
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const navigate = useNavigate();
  const {slug} = useParams();
  const gqlClient = useStore($gqlClient);

  const page = useSWR(gqlGetStores(language, `id eq '${slug}'`), gqlClient).data?.queryStoresContents[0].data;
  const merchant = page?.merchant.iv[0].flatData;

  return (
    <article className={css.Store}>
      <Head title={page?.title[language]} description={``} />

      {!page && (
        <div className="loader-container">
          <Loader />
        </div>
      )}

      {page && (
        <div className="container pad">
          <div className={`top-section-with-bg ${css.topSection}`}>
            <div className="container --s pad">
              <button aria-label="Back" className="back-button" onClick={() => navigate(-1)}>
                <IoArrowBack size={24} />
              </button>

              <div className="page-header">
                <h1 className="fs --42 --black page-title">{page.title[language].toUpperCase()}</h1>
                <h2 className="fs --22 --bold page-subtitle">{page.address[language]}</h2>
              </div>

              <div className={css.image}>
                <Image alt={page.title[language]} src={`${merchant.image[0].id}?width=900`} />
              </div>

              <div className={css.logo}>
                <Image alt={`${merchant.title} logo`} src={`${merchant.logo[0].id}?width=200`} />
              </div>

              <div className={css.contactButtons}>
                <a href={`tel:${merchant.phone}`}>
                  <div className={css.icon}>
                    <IoCall size={20} />
                  </div>
                  <div className={css.text}>{t('call')}</div>
                </a>
                <a href={`mailto:${merchant.email}`}>
                  <div className={css.icon}>
                    <IoMail size={20} />
                  </div>
                  <div className={css.text}>Email</div>
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${page?.location.iv[0].latitude}%2C${page?.location.iv[0].longitude}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={css.icon}>
                    <IoMap size={20} />
                  </div>
                  <div className={css.text}>{t('map')}</div>
                </a>
              </div>

              <div
                className={`fs --22 ${css.description}`}
                dangerouslySetInnerHTML={{__html: page.description[language]}}
              ></div>
            </div>
          </div>
        </div>
      )}
    </article>
  );
}
