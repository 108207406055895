import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useStore} from 'effector-react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sq from 'date-fns/locale/sq';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {validateEmail} from '../../lib/utils';

import {
  $profile,
  $OTP,
  OTPValidateFx,
  getMemberFx,
  updateMobileOTPFx,
  updateMemberMobileFx,
  updateMemberDataFx,
  logoutFx,
} from '../../models/auth/auth';
import {showToastFx} from '../../models/components/components';

import css from './Account.module.scss';
import Dropdown from '../../components/Dropdown';
import FormControlError from '../../components/FormControlError';
import Loader from '../../components/Loader';

registerLocale('sq', sq);
dayjs.extend(customParseFormat);

const minimumAgeYears = 16;
const pinRequestTimeoutSeconds = 90;
let pinRaf: any;

export default function Profile() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const profile = useStore($profile);
  const isUpdating = useStore(updateMemberDataFx.pending);
  const isCheckingMobile = useStore(updateMobileOTPFx.pending);

  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    getMemberFx();
  }, []);

  const {register, getValues, setValue, watch, trigger, reset, handleSubmit, formState} = useForm<any>({
    defaultValues: profile,
  });

  const dateOfBirth = watch().dateOfBirth;

  const isValidMobile = (mobile: string) => {
    const rx = new RegExp('6[0-9]{8}$');
    return rx.test(mobile);
  };

  const onSubmit = async (values: any) => {
    const mobileChanged = profile?.mobile !== values.mobile;

    const payload = {
      ...values,
      dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
      isMale: !!values.isMale,
    };

    if (mobileChanged) {
      modalRef.current?.show();
    } else {
      const res = await updateMemberDataFx(payload);
      if (res) showToastFx({text: t('profile-updated'), status: 'success'});
    }
  };

  return (
    <div className={css.Profile}>
      {!profile && <Loader />}

      {profile && (
        <div className={css.profileFormContainer}>
          <form className={css.ProfileForm}>
            <fieldset>
              <div className="form-control">
                <input {...register('firstname', {required: true})} type="text" placeholder={t('first-name')} />
                {formState.isSubmitted && formState.errors.firstname && <FormControlError />}
              </div>

              <div className="form-control">
                <input {...register('lastname', {required: true})} type="text" placeholder={t('last-name')} />
                {formState.isSubmitted && formState.errors.lastname && <FormControlError />}
              </div>

              <div className="form-control --dropdown">
                <Dropdown
                  placeholder={t('gender')}
                  options={[
                    {label: t('male'), value: 1},
                    {label: t('female'), value: 2},
                  ]}
                  initialIndex={profile.isMale ? 0 : 1}
                  onChange={(option) => {
                    setValue('isMale', option.value === 1 ? 1 : 0);
                    trigger();
                  }}
                />
                <input {...register('isMale', {required: true})} type="hidden" />
                {formState.isSubmitted && formState.errors.isMale && <FormControlError />}
              </div>

              <div className="form-control">
                <DatePicker
                  locale={language}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={80}
                  selected={new Date(dateOfBirth)}
                  maxDate={dayjs().subtract(minimumAgeYears, 'years').toDate()}
                  value={dayjs(dateOfBirth).format('DD/MM/YYYY')}
                  placeholderText={t('date-of-birth')}
                  onChange={(date) => {
                    setValue('dateOfBirth', date);
                    trigger();
                  }}
                />
                <input {...register('dateOfBirth', {required: true})} type="hidden" />
                {formState.isSubmitted && formState.errors.dateOfBirth && <FormControlError />}
              </div>

              <div className="form-control">
                <input
                  {...register('mobile', {required: true, validate: {mobile: (value) => isValidMobile(value)}})}
                  type="tel"
                  placeholder="6XXXXXXXX"
                />
                {formState.isSubmitted && formState.errors.mobile && <FormControlError />}
              </div>

              <div className="form-control">
                <input
                  {...register('email', {
                    validate: {email: (value) => (value ? validateEmail(value) : true)},
                  })}
                  type="email"
                  placeholder="Email"
                />
                {formState.isSubmitted && formState.errors.email && <FormControlError />}
              </div>

              <div className="form-control">
                <input {...register('town', {required: true})} type="text" placeholder={t('city')} />
                {formState.isSubmitted && formState.errors.town && <FormControlError />}
              </div>

              <div className="form-control">
                <input {...register('addressLine')} type="text" placeholder={t('address')} />
                {formState.isSubmitted && formState.errors.addressLine && <FormControlError />}
              </div>

              <div className="form-control --card-number">
                <label aria-disabled>{t('card-number')}:</label>
                <input value={profile.cardNumber} type="text" disabled />
              </div>
            </fieldset>

            <button
              type="button"
              className="button"
              disabled={isUpdating || isCheckingMobile}
              onClick={handleSubmit(onSubmit)}
            >
              {t('update-profile').toUpperCase()}
            </button>
          </form>
        </div>
      )}

      <dialog ref={modalRef} className={css.ProfileVerifyModal}>
        <ProfileVerify
          mobile={getValues().mobile}
          onCancel={() => {
            modalRef.current?.close();
            reset();
          }}
        />
      </dialog>
    </div>
  );
}

function ProfileVerify({mobile, onCancel}: {mobile: string; onCancel: () => void}) {
  const {t} = useTranslation();
  const storedOTP = useStore($OTP);
  const isRequestingOTP = useStore(updateMobileOTPFx.pending);
  const isValidatingOTP = useStore(OTPValidateFx.pending);

  const initialOTP = useMemo(() => ({1: '', 2: '', 3: '', 4: '', 5: '', 6: ''}), []);

  const [step, setstep] = useState(1);

  const [secondsCountdown, setSecondsCountdown] = useState(pinRequestTimeoutSeconds);
  const [OTP, setOTP] = useState(initialOTP);

  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const inputRef6 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    async function onValidate() {
      const otpString = Object.values(OTP).join('');
      const res = await OTPValidateFx({mobile, pin: otpString});

      if (res === false) {
        const updateMobileRes = await updateMemberMobileFx(mobile);
        if (updateMobileRes) logoutFx();
      }
    }

    if (OTP[1] && OTP[2] && OTP[3] && OTP[4] && OTP[5] && OTP[6]) {
      onValidate();
    }
  }, [OTP, mobile]);

  useEffect(() => {
    const getSecondsElapsed = () => Number(((+new Date() - storedOTP.timestamp) / 1000).toFixed(0));

    function startTimer() {
      const countdown = pinRequestTimeoutSeconds - getSecondsElapsed();
      setSecondsCountdown(countdown);
      pinRaf = requestAnimationFrame(startTimer);
      if (countdown <= 0) return cancelAnimationFrame(pinRaf);
    }

    if (step === 2) startTimer();
  }, [storedOTP, step]);

  const onChange = (id: number, value: string) => {
    const isNumericRx = new RegExp('\\d');
    const isNumeric = isNumericRx.test(value);
    if (!isNumeric) return;

    switch (id) {
      case 1:
        setOTP({...OTP, 1: value});
        if (value) inputRef2.current?.focus();
        break;
      case 2:
        setOTP({...OTP, 2: value});
        if (value) inputRef3.current?.focus();
        break;
      case 3:
        setOTP({...OTP, 3: value});
        if (value) inputRef4.current?.focus();
        break;
      case 4:
        setOTP({...OTP, 4: value});
        if (value) inputRef5.current?.focus();
        break;
      case 5:
        setOTP({...OTP, 5: value});
        if (value) inputRef6.current?.focus();
        break;
      case 6:
        setOTP({...OTP, 6: value});
        break;
    }
  };

  const onKeyUp = (id: number, key: string) => {
    if (key === 'Backspace') {
      switch (id) {
        case 1:
          setOTP({...OTP, 1: ''});
          break;
        case 2:
          inputRef1.current?.focus();
          setOTP({...OTP, 2: ''});
          break;
        case 3:
          inputRef2.current?.focus();
          setOTP({...OTP, 3: ''});
          break;
        case 4:
          inputRef3.current?.focus();
          setOTP({...OTP, 4: ''});
          break;
        case 5:
          inputRef4.current?.focus();
          setOTP({...OTP, 5: ''});
          break;
        case 6:
          inputRef5.current?.focus();
          setOTP({...OTP, 6: ''});
          break;
      }
    }
  };

  const onCancelRequest = useCallback(() => {
    cancelAnimationFrame(pinRaf);
    setOTP(initialOTP);
    setstep(1);
    onCancel();
  }, [initialOTP, onCancel]);

  const onGoToStep2 = async () => {
    const res = await updateMobileOTPFx(mobile);
    if (res) {
      setstep(2);
    }
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (secondsCountdown <= 0) onCancelRequest();
  }, [secondsCountdown, onCancelRequest]);

  useEffect(() => {
    window.addEventListener('keyup', (e) => (e.key === 'Escape' ? onCancelRequest() : null));
    return () => window.removeEventListener('keyup', (e) => (e.key === 'Escape' ? onCancelRequest() : null));
  }, [onCancelRequest]);

  return (
    <div className={css.ProfileVerify}>
      <div className={css.inner}>
        {step === 1 && (
          <>
            <div className={css.header}>
              <h2 className="fs --25 --black">{t('new-mobile-number').toUpperCase()}</h2>
              <h3 className="fs --20 --medium">{mobile}</h3>
              <p className="fs --20 --medium">{t('new-mobile-number-message')}</p>
            </div>

            <div className={css.step1Actions}>
              <button type="button" className="button --blue" onClick={onCancelRequest}>
                {t('cancel').toUpperCase()}
              </button>
              <button type="button" className="button" disabled={isRequestingOTP} onClick={onGoToStep2}>
                {t('verify-new-mobile').toUpperCase()}
              </button>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div className={css.header}>
              <h2 className="fs --25 --black">{t('please-enter-otp-verification').toUpperCase()}</h2>

              <h3 className="fs --20 --medium">
                {t('code-sent-to')} {mobile}
              </h3>
            </div>

            <form className={css.verificationForm} onSubmit={onSubmit}>
              <fieldset>
                <input
                  ref={inputRef1}
                  type="text"
                  maxLength={1}
                  value={OTP[1]}
                  onChange={(e) => onChange(1, e.target.value)}
                  onKeyUp={({nativeEvent}) => onKeyUp(1, nativeEvent.key)}
                  autoFocus
                />
                <input
                  ref={inputRef2}
                  type="text"
                  maxLength={1}
                  value={OTP[2]}
                  onChange={(e) => onChange(2, e.target.value)}
                  onKeyUp={({nativeEvent}) => onKeyUp(2, nativeEvent.key)}
                />
                <input
                  ref={inputRef3}
                  type="text"
                  maxLength={1}
                  value={OTP[3]}
                  onChange={(e) => onChange(3, e.target.value)}
                  onKeyUp={({nativeEvent}) => onKeyUp(3, nativeEvent.key)}
                />
                <input
                  ref={inputRef4}
                  type="text"
                  maxLength={1}
                  value={OTP[4]}
                  onChange={(e) => onChange(4, e.target.value)}
                  onKeyUp={({nativeEvent}) => onKeyUp(4, nativeEvent.key)}
                />
                <input
                  ref={inputRef5}
                  type="text"
                  maxLength={1}
                  value={OTP[5]}
                  onChange={(e) => onChange(5, e.target.value)}
                  onKeyUp={({nativeEvent}) => onKeyUp(5, nativeEvent.key)}
                />
                <input
                  ref={inputRef6}
                  type="text"
                  maxLength={1}
                  value={OTP[6]}
                  onChange={(e) => onChange(6, e.target.value)}
                  onKeyUp={({nativeEvent}) => onKeyUp(6, nativeEvent.key)}
                />
              </fieldset>
            </form>

            <div className={css.loaderContainer}>{isValidatingOTP && <Loader />}</div>

            <div className={css.step2Actions}>
              <button type="button" className="button --white" onClick={onCancelRequest}>
                {t('cancel').toUpperCase()} <span>({secondsCountdown})</span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
