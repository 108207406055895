import React from 'react';

type Props = {
  on: boolean;
  onClick: () => void;
};

export default function Switch({on, onClick}: Props) {
  return (
    <button aria-label="on-off switch" className="Switch" data-on={on} onClick={onClick}>
      <div className="Switch-inner" />
    </button>
  );
}
