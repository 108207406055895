import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import dayjs from 'dayjs';

import {getPointsHistoryFx, $pointsHistory} from '../../models/auth/auth';

import css from './Account.module.scss';
import Loader from '../../components/Loader';

const pageSize = 10;

export default function PointsHistory() {
  const {t} = useTranslation();
  const pointsHistory = useStore($pointsHistory);
  const isLoading = useStore(getPointsHistoryFx.pending);
  const [pageNumber, setpageNumber] = useState(1);

  const items = pointsHistory && Object.values(pointsHistory.items).flat();
  const remainingItems = pointsHistory?.totalItems - items?.length;

  useEffect(() => {
    if (items) {
      setpageNumber(items.length / pageSize);
    } else {
      getPointsHistoryFx({pageNumber: 1, pageSize});
    }
  }, [items]);

  return (
    <div className={css.PointsHistory}>
      {!pointsHistory && isLoading && <Loader />}

      {items?.map((item: any, i: number) => (
        <PointsHistoryItem key={i} {...item} />
      ))}

      {pointsHistory && remainingItems > 0 && (
        <div className={css.loadMoreContainer}>
          <div className={css.loadOrInfo}>
            {isLoading ? (
              <Loader />
            ) : (
              <p>
                {remainingItems} {t('more').toLowerCase()}
              </p>
            )}
          </div>

          <button
            className="button"
            disabled={isLoading}
            onClick={() => getPointsHistoryFx({pageNumber: pageNumber + 1, pageSize})}
          >
            {t('load-more').toUpperCase()}
          </button>
        </div>
      )}
    </div>
  );
}

function PointsHistoryItem(item: any) {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  return (
    <div className={css.PointsHistoryItem}>
      <div className={css.icon}>
        {item.points > 0 ? (
          <svg viewBox="0 0 15 21">
            <use xlinkHref="#svg-reward" />
          </svg>
        ) : (
          <svg viewBox="0 0 13 18">
            <use xlinkHref="#svg-transaction" />
          </svg>
        )}
      </div>

      <div className={css.body}>
        <p className={css.date}>{dayjs(item.logDate).format('DD-MM-YYYY')}</p>
        <p className={css.description}>
          {item.remarks ? item.remarks : item.invoiceNumber ? `${t('invoice')}: ${item.invoiceNumber}` : item.source}
        </p>
        {language === 'en' && item.enStoreName && <p className={css.PointsHistoryItem_merchant}>{item.enStoreName}</p>}
        {language === 'sq' && item.alStoreName && <p className={css.PointsHistoryItem_merchant}>{item.alStoreName}</p>}
      </div>

      <div className={css.points}>
        <p style={{color: item.points >= 0 ? '#26961F' : '#EC1C24'}}>
          <span className="fs --20 --black">
            {item.points >= 0 ? `+ ${item.points}` : `- ${Math.abs(item.points)}`}
          </span>
        </p>
        {item.cashAmount && (
          <p className={css.pointsAmountSpent}>
            <span>{parseInt(item.cashAmount)}</span>
            <span>ALL</span>
          </p>
        )}
      </div>
    </div>
  );
}
