import {createEvent, createEffect, createStore, attach, guard, forward} from 'effector';
import axios from 'axios';

import {ApiResponse} from '../app_types';
import {$token, getMemberFx} from '../auth/auth';
import {showToastFx} from '../components/components';

type OffersFilters = {
  canBuy: boolean;
  merchants: string[];
  sort: 'recent';
};

/**
 * Filters
 */
export const $filters = createStore<OffersFilters>({
  canBuy: false,
  merchants: [],
  sort: 'recent',
});

export const setFilters = createEvent<Partial<OffersFilters>>();
export const resetFilters = createEvent();

$filters.on(setFilters, (state, values) => ({...state, ...values})).reset(resetFilters);

/**
 * Coupons
 */
const buyCoupon = createEffect(async ({couponSetCode, token}: {couponSetCode: string; token: string}) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/buy-coupon', {couponSetCode, token});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  if (data.value) showToastFx({text: data.message, status: 'error'});
  return true;
});

export const buyCouponFx = attach({
  effect: buyCoupon,
  source: $token,
  mapParams: (couponSetCode: string, $token: string) => ({couponSetCode, token: $token}),
});

/**
 * Conditional actions
 * ========================================================
 */
guard({
  source: buyCouponFx.doneData,
  filter: (value) => value === true,
  target: getMemberFx,
});
