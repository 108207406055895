import {gql} from 'graphql-request';

export const gqlGetOffers = (locale: string) => gql`
  query GetOffers {
    queryOffersContents(orderby: "data/priority/iv asc") {
      id
      data {
        merchant {
          iv {
            ... on Merchants {
              id
              flatData {
                title
                logo {
                  id
                }
                image {
                  id
                }
              }
            }
          }
        }
        couponCode {
          iv
        }
        title {
          ${locale}
        }
        description {
          ${locale}
        }
        offerTerms {
          ${locale}
        }
        couponTerms {
          ${locale}
        }
        activeFrom {
          iv
        }
        activeUntil {
          iv
        }
        duration {
          iv
        }
        discountAmount {
          iv
        }
        costInPoints {
          iv
        }
        image {
          iv {
            id
          }
        }
        displayDaysUntilExpiration {
          iv
        }
        minRemainingCoupons {
          iv
        }
        city {
          iv {
            ... on Cities {
              id
              flatData {
                name
              }
            }
          }
        }
        businessCategory {
          iv {
            ... on Businesscategories {
              id
              flatData {
                category
              }
            }
          }
        }
      }
    }
  }
`;

export const gqlGetCategories = (locale: string) => gql`
  query GetCategories {
    queryBusinesscategoriesContents(orderby: "data/Category/${locale} asc") {
      id
      flatData {
        category
      }
    }
  }
`;
export const gqlGetCities = (locale: string) => gql`
  query GetCities {
    queryCitiesContents(orderby: "data/Name/${locale} asc") {
      id
      flatData {
        name
      }
    }
  }
`;

export const gqlGetPartnersOffers = (locale: string) => gql`
  query GetPartnersOffers {
    queryAfffiliateoffersContents(orderby: "data/priority/iv asc") {
      id
      data {
        merchant {
          iv {
            ... on Merchants {
              id
              flatData {
                title
                logo {
                  id
                }
                image {
                  id
                }
              }
            }
          }
        }
        actionButtonUrl {
          iv
        }
        couponCode {
          iv
        }
        title {
          ${locale}
        }
        description {
          ${locale}
        }
        offerTerms {
          ${locale}
        }
        activeFrom {
          iv
        }
        activeUntil {
          iv
        }
        image {
          iv {
            id
          }
        }
        displayDaysUntilExpiration {
          iv
        }
        city {
          iv {
            ... on Cities {
              id
              flatData {
                name
              }
            }
          }
        }
        businessCategory {
          iv {
            ... on Businesscategories {
              id
              flatData {
                category
              }
            }
          }
        }
      }
    }
  }
`;

export const gqlGetStores = (locale: string, filter: string = '', orderby: string = '') => gql`
  query GetStores {
    queryStoresContents(filter: "${filter}", orderby: "${orderby}") {
      id
      data {
        merchant {
          iv {
            ... on Merchants {
              flatData {
                title
                logo {
                  id
                }
                image {
                  id
                }
                phone,
                email,
                website,
              }
            }
          }
        }
        title {
          ${locale}
        }
        address {
          ${locale}
        }
        image {
          iv {
            id
          }
        }
        phone {
          iv
        }
        email {
          iv
        }
        location {
          iv {
            latitude
            longitude
          }
        }
        description {
          ${locale}
        }
      }
    }
  }
`;
