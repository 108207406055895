import React, {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';
import settings from '../../config/settings';

import {$profile, resetPointsHistory} from '../../models/auth/auth';

import css from './Account.module.scss';
import Head from '../../components/Head';
import Link from '../../components/Link';

export default function Account() {
  const {t, i18n} = useTranslation();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const profile = useStore($profile);

  const path = i18n.language === settings.defaultLocale ? pathname : pathname.slice(3);
  const getTitle = () => t(path.split('/')[2]);

  useEffect(() => {
    resetPointsHistory();
  }, []);

  useEffect(() => {
    if (!profile) navigate('/');
  }, [profile, navigate]);

  return (
    <div className={css.Account}>
      <Head title={getTitle()} description={``} />

      {profile && (
        <div className="container --m pad">
          <div className="page-header">
            <h1 className="fs --42 --black page-title">{t('account').toUpperCase()}</h1>
            <h2 className="fs --25 --medium">{t(path.split('/')[2]).toUpperCase()}</h2>
          </div>

          <nav className={css.accountNav}>
            <div className={css.inner}>
              <Link to={`/account/profile`} data-active={path === '/account/profile'}>
                <span className="fs --25 --medium">{t('profile').toUpperCase()}</span>
              </Link>
              <Link to={`/account/points-history`} data-active={path === '/account/points-history'}>
                <span className="fs --25 --medium">{t('points-history').toUpperCase()}</span>
              </Link>
              <Link to={`/account/my-favorite-offers`} data-active={path === '/account/my-favorite-offers'}>
                <span className="fs --25 --medium">{t('my-favorite-offers').toUpperCase()}</span>
              </Link>
              <Link to={`/account/my-coupons`} data-active={path === '/account/my-coupons'}>
                <span className="fs --25 --medium">{t('my-coupons').toUpperCase()}</span>
              </Link>
              <Link to={`/account/booklets`} data-active={path === '/account/booklets'}>
                <span className="fs --25 --medium">{t('booklets').toUpperCase()}</span>
              </Link>
            </div>
          </nav>

          <Outlet />
        </div>
      )}
    </div>
  );
}
