import React from 'react';
import {useTranslation} from 'react-i18next';
import useSWR from 'swr';

import css from './Footer.module.scss';
import Link from './Link';

export default function Footer() {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const contactPage = useSWR(`/contact-page`).data?.items[0].data;
  const partnersComp = useSWR(`/become-a-partner-comp`).data?.items[0].data;

  return (
    <footer className={css.Footer}>
      <div className={css.footerNavContainer}>
        <nav className={css.footerNav}>
          <div className={css.footerNav_inner}>
            <div>
              <Link to={`/how-it-works`}>{t('how-it-works')}</Link>
            </div>
            <div>
              <Link to={`/faq`}>{t('faq')}</Link>
            </div>
            <div>
              <Link to={`/our-partners`}>{t('our-partners')}</Link>
            </div>
            <div>
              <Link to={`/benefits`}>{t('benefits')}</Link>
            </div>
            <div>
              <Link to={`/offers`}>{t('offers')}</Link>
            </div>
            <div>
              <Link to={`/contact-us`}>{t('contact-us')}</Link>
            </div>
            <div>
              <Link to={`/terms-conditions`}>{t('terms-and-conditions')}</Link>
            </div>
          </div>
        </nav>
      </div>

      <div className={css.footerMain}>
        <div className={css.appStores}>
          <p className={css.title}>{t('download-the-happy-app')}</p>
          <div className={css.links}>
            <a href={contactPage?.appleStoreUrl.iv} target="_blank" rel="noreferrer">
              <img alt="Download on the app store" src="/images/app-store.png" />
            </a>
            <a href={contactPage?.googleStoreUrl.iv} target="_blank" rel="noreferrer">
              <img alt="Get it on google play" src="/images/play-store.png" />
            </a>
          </div>
        </div>

        <div className={css.partnersComp}>
          <h3 className={css.title}>
            <span className="fs --32 --black">{partnersComp?.title[language]}</span>
          </h3>
          <div className={css.teaser}>
            <span className="fs --22 --medium">{partnersComp?.teaser[language]}</span>
          </div>
          <Link className="button" to="/become-a-partner">
            {partnersComp?.buttonLabel[language].toUpperCase()}
          </Link>
        </div>

        <div className={css.contactInfo}>
          <p>{contactPage?.address.iv}</p>

          <div className={css.row}>
            <p>
              <span>E:</span> <a href={`mailto:${contactPage?.email.iv}`}>{contactPage?.email.iv}</a>,
            </p>

            <p>
              <span>T:</span> <a href={`tel:${contactPage?.phone.iv}`}>{contactPage?.phone.iv}</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
