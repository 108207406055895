import React, {useEffect, useState, useRef, useMemo} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import {Loader as MapsLoader} from '@googlemaps/js-api-loader';
import {MarkerClusterer} from '@googlemaps/markerclusterer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {IoArrowBack, IoOptions, IoHeartOutline} from 'react-icons/io5';
import {gqlGetCategories, gqlGetCities, gqlGetOffers, gqlGetPartnersOffers} from '../lib/graphql_queries';
import settings from '../config/settings';
import en from 'dayjs/locale/de';
import sq from 'dayjs/locale/sq';

import {$gqlClient} from '../models/app';
import {$profile, $favoriteOffers, getFavoritesFx, addFavoritesFx, removeFavoritesFx} from '../models/auth/auth';
import {setPopup, showToastFx} from '../models/components/components';
import {buyCouponFx} from '../models/offers/offers';

import css from './Offers.module.scss';
import Dropdown, {DropdownOption} from '../components/Dropdown';
import Head from '../components/Head';
import Image from '../components/Image';
import Link from '../components/Link';
import Loader from '../components/Loader';
import Switch from '../components/Switch';

dayjs.extend(relativeTime);

const _W: any = window;
const isProduction = process.env.NODE_ENV === 'production';
const mapsApiKey = isProduction ? settings.googleMapsApiKey.prod : settings.googleMapsApiKey.dev;

type FiltersType = {
  merchant: string;
  city: string;
  category: string;
  canBuy: boolean;
};

export default function Offers() {
  const gqlClient = useStore($gqlClient);
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const profile = useStore($profile);
  const [isReady, setisReady] = useState(false);
  const [filters, setfilters] = useState<FiltersType>({merchant: '', canBuy: false, city: '', category: ''});

  const [happyItemsFiltered, sethappyItemsFiltered] = useState<any[]>([]);
  const [partnersItemsFiltered, setpartnersItemsFiltered] = useState<any[]>([]);

  const pageRes = useSWR(`/offers-page`);
  const page = pageRes.data?.items[0].data;
  const happyItemsRes = useSWR(gqlGetOffers(language), gqlClient);
  const happyItems = happyItemsRes.data?.queryOffersContents;

  const partnersItemsRes = useSWR(gqlGetPartnersOffers(language), gqlClient);
  const partnersItems = partnersItemsRes.data?.queryAfffiliateoffersContents;

  const citiesItemsRes = useSWR(gqlGetCities(language), gqlClient);
  const citiesItems = citiesItemsRes.data?.queryCitiesContents;

  const categoriesItemsRes = useSWR(gqlGetCategories(language), gqlClient);
  const categoriesItems = categoriesItemsRes.data?.queryBusinesscategoriesContents;

  const [activeTab, setActiveTab] = useState<string>('partnersOffers');

  const merchantsOptions = useMemo(() => {
    let merchantsOptions: DropdownOption[] = [];

    happyItems?.forEach((item: any) => {
      const merchant = item.data.merchant.iv[0];
      const merchantExists = merchantsOptions.find((item: any) => item.value === merchant.id);
      if (!merchantExists) {
        merchantsOptions.push({
          label: merchant.flatData.title.toUpperCase(),
          value: merchant.id,
        });
      }
    });
    partnersItems?.forEach((item: any) => {
      const merchant = item.data.merchant.iv[0];
      const merchantExists = merchantsOptions.find((item: any) => item.value === merchant.id);
      if (!merchantExists) {
        merchantsOptions.push({
          label: merchant.flatData.title.toUpperCase(),
          value: merchant.id,
        });
      }
    });

    merchantsOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

    return merchantsOptions;
  }, [happyItems, partnersItems]);

  const citiesOptions = useMemo(() => {
    let citiesOptions: DropdownOption[] = [];
    citiesItems &&
      citiesItems.map((item: any) => {
        citiesOptions.push({
          label: item.flatData.name.toUpperCase(),
          value: item.id,
        });
      });

    return citiesOptions;
  }, [language, citiesItems]);

  const categoriesOptions = useMemo(() => {
    let categoriesOptions: DropdownOption[] = [];
    categoriesItems &&
      categoriesItems.map((item: any) => {
        categoriesOptions.push({
          label: item.flatData.category.toUpperCase(),
          value: item.id,
        });
      });

    return categoriesOptions;
  }, [categoriesItems, language]);

  const onSetFilters = (value: Partial<FiltersType>) => {
    setfilters({...filters, ...value});
  };

  useEffect(() => {
    if (happyItems && partnersItems) {
      updateFilteredItems();
      setisReady(true);
    }
  }, [happyItems, partnersItems]);

  useEffect(() => {
    if (isReady) updateFilteredItems();
  }, [filters]);

  function updateFilteredItems() {
    let itemsHappy = [...happyItems];
    let itemsPartners = [...partnersItems];
    const filteredItemsPartners: any[] = [];
    const filteredItemsOffers: any[] = [];

    itemsPartners.map((item) => (item.offerType = 'partners'));

    if (filters.canBuy) {
      itemsHappy = itemsHappy.filter((item) => (profile ? profile.poinBalance >= item.data.costInPoints.iv : true));
    }

    if (filters.merchant.length) {
      itemsHappy = itemsHappy.filter((item) => filters.merchant.includes(item.data.merchant.iv[0].id));
      itemsPartners = itemsPartners.filter((item) => filters.merchant.includes(item.data.merchant.iv[0].id));
    }
    if (filters.city.length) {
      itemsHappy = itemsHappy.filter((item) =>
        filters.city.includes(item.data.city && item.data.city.iv && item.data.city.iv[0].id),
      );

      itemsHappy.map((item) => {
        const filteredItem = item.data.city.iv.filter((e: any) => e.id === filters.city);
        filteredItem.length && itemsHappy.push(item);
      });
      itemsHappy = filteredItemsOffers;

      itemsPartners.map((item) => {
        const filteredItem = item.data.city.iv.filter((e: any) => e.id === filters.city);
        filteredItem.length && filteredItemsPartners.push(item);
      });
      itemsPartners = filteredItemsPartners;
    }

    if (filters.category.length) {
      itemsHappy = itemsHappy.filter((item) =>
        filters.category.includes(
          item.data.businessCategory && item.data.businessCategory.iv && item.data.businessCategory.iv[0].id,
        ),
      );
      itemsPartners = itemsPartners.filter((item) =>
        filters.category.includes(
          item.data.businessCategory && item.data.businessCategory.iv && item.data.businessCategory.iv[0].id,
        ),
      );
    }
    sethappyItemsFiltered(itemsHappy || []);
    setpartnersItemsFiltered(itemsPartners || []);
  }
  return (
    <>
      {!isReady && (!pageRes || !happyItems) && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {isReady && page && pageRes && happyItems && (
        <div className={css.Offers} data-ready={isReady}>
          <Head
            title={page?.title[language]}
            description={page?.metaDescription ? page.metaDescription[language] : ''}
          />

          <div className="container pad">
            <div className={css.topSection}>
              <div className="page-header">
                <h1 className="fs --42 --black page-title">{page?.title[language].toUpperCase()}</h1>
                {page?.subtitle[language] && (
                  <h2 className="fs --25 --medium page-subtitle">{page?.subtitle[language].toUpperCase()}</h2>
                )}
              </div>

              <div className={css.image}>
                {page && (
                  <Image
                    alt={page.featuredItem[language][0].title}
                    src={page.featuredItem[language][0].image}
                    srcSet={page.featuredItem[language][0].image}
                    onLoad={() => setisReady(true)}
                  />
                )}
              </div>

              <div className={css.meta}>
                <h2 className={`fs --48 --black`}>{page?.featuredItem[language][0].title}</h2>
                <p className={`fs --25 --medium`}>{page?.featuredItem[language][0].subtitle}</p>
              </div>
            </div>
            <div className={css.toolbar}>
              <OffersToolbar
                merchantsOptions={merchantsOptions}
                citiesOptions={citiesOptions}
                categoriesOptions={categoriesOptions}
                filters={filters}
                onSetFilters={onSetFilters}
              />
            </div>
            <div className={`${css.tabHeader} fs --18`}>
              <div
                onClick={() => setActiveTab('partnersOffers')}
                className={`${css.tabHeaderItem} ${
                  activeTab === 'partnersOffers' ? `${css.isActive}  fs --black` : 'fs --medium'
                }`}
              >
                {t('partners-offers-tab')}
              </div>
              <div
                onClick={() => setActiveTab('happyOffers')}
                className={`${css.tabHeaderItem} ${
                  activeTab === 'happyOffers' ? `${css.isActive}  fs --black` : 'fs --medium'
                }`}
              >
                {t('happy-offers-tab')}
              </div>
            </div>
            {activeTab === 'happyOffers' && (
              <div className={`${css.tabContent} dol`}>
                {!happyItemsFiltered?.length && happyItemsRes.isValidating && (
                  <div className={css.loadOrEmpty}>
                    <Loader />
                  </div>
                )}
                {!happyItemsFiltered?.length && !happyItemsRes.isValidating && (
                  <div className={css.loadOrEmpty}>
                    <div className={css.empty}>{t('the-list-is-empty')}</div>
                  </div>
                )}
                <div className={css.grid}>
                  {happyItemsFiltered?.map((item: any) => (
                    <OfferItem key={item.id} {...item} />
                  ))}
                </div>
              </div>
            )}
            {activeTab === 'partnersOffers' && (
              <div className={`${css.tabContent} dol`}>
                {!partnersItemsFiltered?.length && partnersItemsRes.isValidating && (
                  <div className={css.loadOrEmpty}>
                    <Loader />
                  </div>
                )}
                {!partnersItemsFiltered?.length && !partnersItemsRes.isValidating && (
                  <div className={css.loadOrEmpty}>
                    <div className={css.empty}>{t('the-list-is-empty')}</div>
                  </div>
                )}
                <div className={css.grid}>
                  {partnersItemsFiltered?.map((item: any) => (
                    <OfferItem key={item.id} {...item} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

type OffersToolbarProps = {
  merchantsOptions: DropdownOption[];
  citiesOptions: DropdownOption[];
  categoriesOptions: DropdownOption[];
  filters: FiltersType;
  onSetFilters: (filters: Partial<FiltersType>) => void;
};

function OffersToolbar({
  merchantsOptions,
  citiesOptions,
  categoriesOptions,
  filters,
  onSetFilters,
}: OffersToolbarProps) {
  const {t} = useTranslation();
  const profile = useStore($profile);
  const merchant = merchantsOptions.find((item: any) => item.value === filters.merchant);
  const city = citiesOptions.find((item: any) => item.value === filters.city);
  const category = categoriesOptions.find((item: any) => item.value === filters.category);
  const [isFiltersPopupOpen, setisFiltersPopupOpen] = useState(false);

  const merchantsDropdownRef = useRef<any>(null);
  const citiesDropdownRef = useRef<any>(null);
  const categoriesDropdownRef = useRef<any>(null);

  const clearFilters = () => {
    onSetFilters({merchant: '', canBuy: false, city: '', category: ''});
    setisFiltersPopupOpen(false);
    merchantsDropdownRef.current?.setIndex(undefined);
    citiesDropdownRef.current?.setIndex(undefined);
    categoriesDropdownRef.current?.setIndex(undefined);
  };

  return (
    <div className={css.OffersToolbar}>
      <div
        className={`${css.item} ${css.itemFilters}`}
        data-open={isFiltersPopupOpen}
        data-active={!!filters.merchant || filters.canBuy || !!filters.city || !!filters.category}
      >
        <button
          aria-label="Toggle filters menu"
          className={css.toggleButton}
          onClick={() => setisFiltersPopupOpen(!isFiltersPopupOpen)}
        >
          <IoOptions size={34} />
        </button>

        <div className={css.itemFiltersPopup}>
          <div className={css.outerClose} onClick={() => setisFiltersPopupOpen(false)} />
          <div className={css.inner}>
            <p className={css.popupTitle}>{t('filters').toUpperCase()}</p>

            {filters.merchant && merchant && (
              <p className={css.filterTitle}>
                {t('merchant').toUpperCase()}: <span>{merchant.label}</span>
              </p>
            )}
            {filters.city && city && (
              <p className={css.filterTitle}>
                {t('city').toUpperCase()}: <span>{city.label}</span>
              </p>
            )}
            {filters.category && category && (
              <p className={css.filterTitle}>
                {t('business-categories').toUpperCase()}: <span>{category.label}</span>
              </p>
            )}

            {filters.canBuy && <p className={css.filterTitle}>{t('show-only-offers-i-can-buy').toUpperCase()}</p>}

            {!!(filters.merchant || filters.canBuy || filters.city || filters.category) && (
              <div className={css.clearFiltersContainer}>
                <button onClick={clearFilters}>{t('clear-all-filters').toUpperCase()}</button>
              </div>
            )}
            {!filters.merchant && filters.city && !filters.category && !filters.canBuy && (
              <p className={css.empty}>{t('no-active-filters')}</p>
            )}
          </div>
        </div>
      </div>

      <div className={`${css.item} ${css.itemMerchant}`}>
        <Dropdown
          ref={merchantsDropdownRef}
          placeholder={t('merchants').toUpperCase()}
          options={merchantsOptions}
          onChange={(option) => onSetFilters({merchant: option.value})}
        />
      </div>
      <div className={`${css.item} ${css.itemMerchant}`}>
        <Dropdown
          ref={citiesDropdownRef}
          placeholder={t('city').toUpperCase()}
          options={citiesOptions}
          onChange={(option) => onSetFilters({city: option.value})}
        />
      </div>
      <div className={`${css.item} ${css.itemMerchant}`}>
        <Dropdown
          ref={categoriesDropdownRef}
          placeholder={t('business-category').toUpperCase()}
          options={categoriesOptions}
          onChange={(option) => onSetFilters({category: option.value})}
        />
      </div>

      {profile && (
        <div className={`${css.item} ${css.itemSwitch}`}>
          <span className="fs --18 --black">{t('show-only-offers-i-can-buy').toUpperCase()}</span>
          <Switch on={filters.canBuy} onClick={() => onSetFilters({canBuy: !filters.canBuy})} />
        </div>
      )}
    </div>
  );
}

export function OfferItem(item: any) {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const slug = encodeURI(item.data.couponCode.iv.replaceAll('/', '-').replaceAll(' ', '-').toLowerCase());
  return (
    <Link className={css.OfferItem} to={`/offers/${item.offerType ? `${item.offerType}/` : ''}${slug}`}>
      <div className={css.image}>
        <Image
          alt={item.data.merchant.iv[0].flatData.title}
          src={
            item.data.image.iv[0]
              ? `${item.data.image.iv[0].id}?width=600`
              : `${item.data.merchant.iv[0].flatData.image[0].id}?width=600`
          }
          loading="lazy"
        />
      </div>

      <div className={css.logo}>
        <Image
          alt={`${item.data.merchant.iv[0].flatData.title} logo`}
          src={`${item.data.merchant.iv[0].flatData.logo[0].id}?width=200`}
          loading="lazy"
        />
      </div>
      {item.data.costInPoints && item.data.costInPoints.iv > 0 && (
        <div className={`fs --20 --bold ${css.points}`}>
          {item.data.costInPoints.iv} {t('points').toLowerCase()}
        </div>
      )}
      <div className={css.meta}>
        <div className={css.partnerAndCoupon}>
          <div className={`fs --20 --black ${css.partner}`}>
            {item.data.merchant.iv[0].flatData.title?.toUpperCase()}
          </div>
          <div className={`fs --medium ${css.couponCode}`}>{item.data.couponCode.iv}</div>
        </div>
        <h3 className={`fs --20 --bold ${css.title}`}>{item.data.title[language]}</h3>
      </div>
    </Link>
  );
}

export function Offer() {
  const gqlClient = useStore($gqlClient);
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const navigate = useNavigate();
  const {slug, type} = useParams();
  const profile = useStore($profile);
  const favoriteOffers = useStore($favoriteOffers);
  const isLoading = useStore(buyCouponFx.pending);
  const isFetchingFavorites = useStore(getFavoritesFx.pending);
  const isAddingFavorite = useStore(addFavoritesFx.pending);
  const isRemovingFavorite = useStore(removeFavoritesFx.pending);
  const offersRes = useSWR(gqlGetOffers(language), gqlClient);
  const partnersOffersRes = useSWR(gqlGetPartnersOffers(language), gqlClient);
  const offers =
    type && type === 'partners'
      ? partnersOffersRes.data?.queryAfffiliateoffersContents
      : offersRes.data?.queryOffersContents;
  const offer = offers?.find(
    (item: any) => item.data.couponCode.iv.replaceAll('/', '-').replaceAll(' ', '-').toLowerCase() === slug,
  )?.data;
  const offerPartner = offer;
  const stores = useSWR(offer ? `/stores?$filter=data/merchant/iv eq '${offer.merchant.iv[0].id}'` : null).data?.items;
  const mapRef = useRef(null);

  const isFavoriteOffer = favoriteOffers?.includes(offer?.couponCode.iv);

  useEffect(() => {
    if (profile && offer) getFavoritesFx();
  }, [profile, offer]);

  const addToFavoriteOffers = async () => {
    await addFavoritesFx([offer.couponCode.iv]);
    showToastFx({text: t('added-to-favorite-offers'), status: 'success'});
  };

  const removeFromFavoriteOffers = async () => {
    await removeFavoritesFx([offer.couponCode.iv]);
    showToastFx({text: t('removed-from-favorite-offers'), status: 'success'});
  };

  useEffect(() => {
    if (!stores) return;
    const loader = new MapsLoader({
      apiKey: mapsApiKey,
      version: 'weekly',
    });

    loader.load().then(() => initMap());

    function initMap() {
      const mapElem = mapRef.current;
      if (!mapElem) return;

      const mapOptions = {
        zoom: 5,
        mapTypeControlOptions: {
          style: _W.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
      };

      const map = new _W.google.maps.Map(mapElem, {...mapOptions});
      const bounds = new _W.google.maps.LatLngBounds();

      let markers: any[] = [];
      let infoWindows: any = [];

      stores.forEach((store: any) => {
        const position = {
          lat: Number(store.data.location.iv[0].latitude),
          lng: Number(store.data.location.iv[0].longitude),
        };
        const marker = new _W.google.maps.Marker({
          map,
          position,
        });

        const infoWindow = new _W.google.maps.InfoWindow({
          content: `<div class=${css.InfoWindow}>
                      <p class=${css.InfoWindow_title}>
                        ${store.data.address[language]}
                      </p>
                      <div class=${css.InfoWindow_contact}><span>T:</span> <a href="tel:${store.data.phone.iv}">${
            store.data.phone.iv
          }</a></div>
                      ${
                        store.data.email.iv
                          ? `<div class=${css.InfoWindow_contact}><span>E:</span> <a href="mailto:${store.data.email.iv}">${store.data.email.iv}</a></div>`
                          : ''
                      }
                    </div>`,
        });

        infoWindows.push(infoWindow);

        marker.addListener('click', () => {
          infoWindows.map((item: any) => item.close());
          infoWindow.open({map, anchor: marker});
        });

        markers.push(marker);

        new MarkerClusterer({markers, map});

        if (stores.length > 1) {
          bounds.extend(position);
          map.fitBounds(bounds);
        } else {
          map.setCenter(position);
          map.setZoom(13);
        }
      });

      map.addListener('click', () => infoWindows.map((item: any) => item.close()));
    }
  }, [stores, language]);

  const onCouponBuy = (couponCode: string) => {
    setPopup({
      id: 'confirm-coupon-purchase',
      couponCode: couponCode,
    });
  };

  if (!offer && offersRes.isValidating)
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );

  return (
    <article className={css.Offer}>
      <Head title={offer?.title[language]} description={offer?.description[language]} />

      {offer && (
        <div className="container pad">
          <div className={`top-section-with-bg ${css.topSection}`}>
            <div className="container --s pad">
              <button aria-label="Back" className="back-button" onClick={() => navigate(-1)}>
                <IoArrowBack size={24} />
              </button>
              <div className="page-header">
                <h1 className="fs --42 --black page-title">{offer.title[language].toUpperCase()}</h1>
              </div>
              <div className={css.image}>
                {profile && favoriteOffers && (
                  <button
                    title={isFavoriteOffer ? t('remove-from-favorite-offers') : t('add-to-favorite-offers')}
                    className={css.favoriteButton}
                    data-favorite={isFavoriteOffer}
                    disabled={isFetchingFavorites || isAddingFavorite || isRemovingFavorite}
                    onClick={isFavoriteOffer ? removeFromFavoriteOffers : addToFavoriteOffers}
                  >
                    <IoHeartOutline />
                  </button>
                )}
                <Image
                  alt={offer.title[language]}
                  src={offer.image ? `${offer.image.iv[0].id}?width=900` : `${offerPartner?.image.iv[0].id}?width=900`}
                />
              </div>
              <div className={css.logo}>
                <Image
                  alt={`${offerPartner.merchant.iv[0].flatData.title} logo`}
                  src={`${offerPartner?.merchant.iv[0].flatData.logo[0].id}?width=200`}
                />
              </div>
              {offer.costInPoints && offer.costInPoints.iv > 0 && (
                <div className={css.metaRow}>
                  <p className={`fs --28 --black ${css.points}`}>
                    {offer.costInPoints.iv} {t('points').toUpperCase()}
                  </p>
                  {/* <p className={`fs --20 --bold ${css.expiry}`}>
                    {t('expires')}{' '}
                    {dayjs(offer.activeUntil.iv)
                      .locale(language === 'en' ? 'en' : 'sq')
                      .fromNow()}
                  </p> */}
                </div>
              )}
              <div
                className={`fs --22 textblock ${css.description}`}
                dangerouslySetInnerHTML={{__html: offer.description[language]}}
              ></div>
              {offer.costInPoints && offer.costInPoints.iv > 0 && (
                <div className={css.action}>
                  {profile && (
                    <p>
                      {(profile?.poinBalance || 0) >= offer.costInPoints.iv
                        ? `${t('yours-with-only')} ${offer.costInPoints.iv} ${t('points').toLowerCase()}`
                        : `${t('keep-collecting-points-to-get-this-offer')}!`}
                    </p>
                  )}

                  {profile ? (
                    <button
                      className="button"
                      disabled={isLoading || (profile?.poinBalance || 0) < offer.costInPoints.iv}
                      // onClick={() => buyCouponFx(offer.couponCode.iv)}
                      onClick={() => onCouponBuy(offer.couponCode.iv)}
                    >
                      {t('buy-a-coupon').toUpperCase()}
                    </button>
                  ) : (
                    <Link className="button" to={`/login`} state={{from: `/offers/${slug}`}}>
                      {t('login-to-buy-a-coupon').toUpperCase()}
                    </Link>
                  )}
                </div>
              )}
              {offer.actionButtonUrl && offer.actionButtonUrl.iv && (
                <div className={css.action}>
                  <a href={offer.actionButtonUrl.iv} className="button" rel="noreferrer" target="_blank">
                    {t('learn-more').toUpperCase()}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className={css.storeSection}>
            <div className="container --s pad">
              <div className={css.description}>
                <h2 className={`fs --28 --black`}>{t('store-description').toUpperCase()}</h2>
                <div
                  className={`fs --20 textblock`}
                  dangerouslySetInnerHTML={{__html: offerPartner?.description[language]}}
                />
              </div>
              <div className={css.map}>
                <h2 className={`fs --28 --black`}>{t('store-locations').toUpperCase()}</h2>

                <div ref={mapRef} className={css.mapCanvas}></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </article>
  );
}
